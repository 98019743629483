import craiova from "../imagescv/craiova.png";
import warsaw from "../imagescv/warsaw.png";
import izmir from "../imagescv/izmirTeknopark.jpg";
import ege from "../imagescv/egetestcenterlogo.png";

const internship = [
   {
      date: "8 Jul 2017 to 20 Aug 2017",
      imageSource: craiova,
      height: "150",
      width: "200",
      city: "Craiova, ROMANIA",
      workType: "Discover Kindergarten",
      certificate: "•	Leadership Development Volunteer Experience Certification",
      sponsor: "Powered by Aiesec Global Volunteer"
   },

   {
      date: "10 Sep 2018 to 19 Oct 2018",
      imageSource: warsaw,
      height: "150",
      width: "200",
      city: "Warsaw, POLAND",
      workType: "Primary Schools",
      certificate: "•	Certificate Of Attendance in International Education Project in Warsaw and Cisie",
      sponsor: "Powered by Aiesec Global Volunteer"
   },

   {
      date: "5 Oct 2019 to 15 Nov 2019",
      imageSource: izmir,
      height: "150",
      width: "200",
      city: "Izmir, TURKEY",
      workType: "Web Development Internship",
      company: "• At DVLX Company",
      sponsor: "Powered by Aiesec Global Entrepreneur",
      project: "• Mobilmed Ecommerce Website",
      projectWebsite: "https://mobilmed.com.tr",
      progLanguages: "• Wordpress | Html5 | Css3 | Woocommerce",
      collaborator: "• Collaboration with Mr. Armagan ERGUN",
      collaboratorWebsite: "https://www.linkedin.com/in/armagan-ergun/"
   },

   {
      date: "18 Nov 2019 to 30 Dec 2019",
      imageSource: ege,
      height: "150",
      width: "200",
      city: "Izmir, TURKEY",
      workType: "Web Development Internship",
      company: "• At Ege Test Center Company",
      companyWebsite: "https://egetestcenter.com",
      sponsor: "Applied on Linkedin",
      project: "• CertifyMyProduct Ecommerce Website",
      projectWebsite: "https://certifymyproduct.com",
      progLanguages: "• Php | JS | Html5 | Css3 | WP",
      collaborator: "• Collaboration with Mr. Ulas Kabukcu"
   }
];

export default internship;
