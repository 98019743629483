import corona from "../imagescv/coronhackathon.jpg";
import asc from "../imagescv/asc.jpg";

const competition = [
   {
      date: "17 Mar 2020 until 17 Aug 2020",
      image: corona,
      height: "150",
      width: "200",
      alt: "Coronhackathon",
      city: "Algiers, ALGERIA",
      challenge: "CORONHACKATHON",
      ranking: "•	Laureate Project.",
      project: "ReBorn, a booking mobile App for haircut and beauty services.",
      eventLink: "https://www.youtube.com/watch?v=d6W2vOhCVyA&list=PLH2kuhyWV6bBdWJCXJoPPbhm0lNgWVmXs",
      event: "Event's video",
      sponsor: "Powered by UNDP, OOREDOO, SANOFI & TELETIC"
   },

   {
      date: "02 Mar 2020 until 19 Dec 2020",
      image: asc,
      height: "150",
      width: "200",
      alt: "Algeria Startup Challenge",
      city: "Algiers, ALGERIA",
      challenge: "ALGERIA STARTUP CHALLENGE",
      ranking: "•	Winner of the first price among 700 projects.",
      project: "ReBorn, a booking mobile App for haircut.",
      eventLink: "https://www.youtube.com/watch?v=XJV_JcjMecc&t=882s",
      event: "Our pitch video",
      sponsor: "Powered by Algérie Poste, OOREDOO, SANOFI, SATIM and YASSIR"
   }
];

export default competition;
