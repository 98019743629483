const motivation = [
   { name: "Exploration", description: "Every experience is a form of exploration." },

   { name: "Innovation", description: "Changes call for innovation, and innovation leads to progress." },

   { name: "Research", description: "Research is creating new knowledge." },

   { name: "Ambition", description: "The desire to reach for the stars is ambitious." },

   { name: "Constant concern for perfection", description: "If you look for perfection, you'll never be content." },

   { name: "Discovery", description: "The best way to find yourself is to lose yourself in the service of others." }
];

export default motivation;
