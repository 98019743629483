import shiftin from "../imagescv/shiftinblogarticle.png";
import mobileappreborn from "../imagescv/mobileappreborn.png";
import ecv from "../imagescv/ecv.png";
import memberapp from "../imagescv/memberapp.png";
import mba from "../imagescv/mba.png";
import foodrecipe from "../imagescv/foodrecipe.png";
import bezwaar24 from "../imagescv/bezwaar24.png";
import schade24 from "../imagescv/schade24.png";
import namereminder from "../imagescv/namereminder.png";
import mobilmed from "../imagescv/mobilmed.png";
import certify from "../imagescv/certify.png";
import formacorp from "../imagescv/formacorp.png";
import snake from "../imagescv/snake.png";
import restaurantapp from "../imagescv/restaurantapp.png";
import travel from "../imagescv/travel.png";
import dicegame from "../imagescv/dicegame.png";
import budgety from "../imagescv/budgety.png";
import forkify from "../imagescv/forkify.png";

const portfolioData = [
   {
      image: mobileappreborn,
      alt: "ReBron mobile app",
      imageLink: "https://play.google.com/store/apps/details?id=com.ayl.tahfifa",
      image2: shiftin,
      alt2: "Shiftin Blog",
      imageLink2: "https://shiftin.co/blog/welcome-to-robot-university-only-robots-need-apply/",
      image3: ecv,
      alt3: "ECV",
      imageLink3: "https://raouf-cv.com/"
   },
   {
      image: memberapp,
      alt: "Member mobile app",
      imageLink: "https://www.youtube.com/watch?v=1SKlUWx3ayg",
      image2: mba,
      alt2: "MBA web page",
      imageLink2:
         "https://www.linkedin.com/posts/raoufmadani_web-bootstrap-softskills-activity-6877269169948250112-fJAz",
      image3: foodrecipe,
      alt3: "Food recipe mobile app",
      imageLink3: "https://www.linkedin.com/feed/update/urn:li:activity:6633104146578841600/"
   },
   {
      image: bezwaar24,
      alt: "Bezwaar 24",
      imageLink: "https://bezwaar24.nl/",
      image2: namereminder,
      alt2: "Name reminder app",
      imageLink2: "https://name-meanings.herokuapp.com/",
      image3: schade24,
      alt3: "Schade24",
      imageLink3: "https://schade24.com/"
   },
   {
      image: mobilmed,
      alt: "Mobilmed ecommerce website",
      imageLink: "https://mobilmed.com.tr/",
      image2: certify,
      alt2: "CertifyMyProduct Ecommerce website",
      imageLink2: "https://certifymyproduct.com/",
      image3: formacorp,
      alt3: "Formacorp website",
      imageLink3: "https://formacorpro.com/"
   },
   {
      image: dicegame,
      alt: "Dice game web app",
      imageLink: "https://tchakroura.raouf-cv.com/",
      image2: budgety,
      alt2: "Budget web app",
      imageLink2: "https://budgety.raouf-cv.com/",
      image3: forkify,
      alt3: "Forkify web app",
      imageLink3: "https://www.linkedin.com/feed/update/urn:li:activity:6624694835796295680//"
   },
   {
      image: restaurantapp,
      alt: "Restaurant web app",
      imageLink: "http://www.gestiondestock.raouf-cv.com/",
      image2: travel,
      alt2: "Travel agency web page",
      imageLink2: "https://travel.raouf-cv.com/",
      image3: snake,
      alt3: "Snake game app",
      imageLink3: "https://snake.raouf-cv.com/"
   }
];

export default portfolioData;
