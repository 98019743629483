const professionalExperience = [
   {
      class: "timeline-panel-container-inverted",
      job: "Javascript Instructor",
      company: "HiCounselor",
      companyWebsite: "https://hicounselor.com/",
      date: " From 09/2022 until 12/2022 San Francisco, California, USA (Remotely)",
      jobDescription: "- Create comprehensive JavaScript courses with Power point.",
      projectName: "- Check out one of my recording sessions.",
      projectLink: "https://www.youtube.com/watch?v=oulwLtgPX2U",
      info: "- Provide effective instructional approach by recording sessions via Zoom.",
      info2: "- Lead interactive live Q&A sessions with students via Zoom."
   },
   {
      class: "timeline-panel-container",
      job: "Consultant Full-Stack developer",
      company: "Garda World Security Corporation",
      companyWebsite: "https://garda.com/",
      date: "  From 02/05/2022 until Now, Montreal (QC) Canada",
      jobDescription: "- Crafting technical analyses prior to commencing tasks.",
      projectName: "- Universal Kiosk Android App.",
      projectLink: "https://play.google.com/store/apps/details?id=com.garda.ukiosk&hl=ca",
      projectName2: "- Universal Kiosk iOS App.",
      projectLink2: "https://apps.apple.com/ca/app/gardaworld-kiosk/id1089638506?uo=4",
      info: "- Executing end-to-end mobile app development and deployment in a scrum team.",
      info2: "- Developing responsive user interfaces for mobile applications.",
      info3: "- Developing backend functionalities to guarantee the seamless operation of the system.",
      info4: "- Collaborating with a team of developers, QA and UAT testers, and DevOps engineers.",
      info5: "- Integrating APIs and web services to retrieve and display real-time data.",
      info6: "- Integrating third party libraries such as Datadog.",
      info7: "- Monitoring logs, performance and RUM sessions in Datadog, Sentry and Graylog.",
      info8: "- Documenting code and processes to facilitate maintenance and long-term collaboration.",
      info9: "- Participating in code reviews and write unit, integration, and load tests as necessary.",
      info10:
         "- Mastering the following tools : SoapUI, Jira, BitBucket, Jenkins, Confluence, Release manager, Slack and Teams.",
      info11:
         "- Mastering the following technologies : Typescript, ES6, React native, Expo, Android, iOS, Soap API, and Redux Saga."
   },
   {
      class: "timeline-panel-container-inverted",
      job: "Co-founder and Full-stack developer",
      company: "SARL AYL GROUP",
      companyWebsite: "https://ayoungleader.com/",
      date: " From 03/2021 until 04/2022 Algiers, Algeria",
      jobDescription: "- Engage in the entire lifecycle of websites development, from ideation to deployment.",
      projectName: "- FormaCorpro website.",
      projectLink: "https://formacorpro.com",
      projectName2: "- MBA careers accelerator web page.",
      projectLink2:
         "https://www.linkedin.com/posts/raoufmadani_web-bootstrap-softskills-activity-6877269169948250112-fJAz/ ",
      projectName3: "- Bezwaar24 web platform (Netherlands, Nimegen).",
      projectLink3: "https://bezwaar24.nl/",
      projectName4: "- Schade24 web platform (Netherlands, Nimegen).",
      projectLink4: "https://schade24.com/",
      info: "- Collaborating with web development team and writing reports.",
      info2: "- Guide the development of new features and capabilities, ensuring that they align with user needs and business goals.",
      info3: "- Develop responsive user interfaces for websites.",
      info4: "- Integrate Rest APIs and web services to retrieve and display real-time data.",
      info5: "- Implement front-end and back-end features to ensure the proper functioning of the application.",
      info6: "- Collaborate with the web development team by reviewing the code via Github and practicing the clean code principles.",
      info7: "- Work closely with UI designers using ClickUp.",
      info8: "- Establish the projects budget and time limit, and lead meetings."
   },

   {
      class: "timeline-panel-container",
      job: "Co-Founder and React full-stack developer",
      company: "Algeria Startup Challenge",
      companyWebsite: "https://algeriastartupchallenge.com/",
      date: " From 03/2020 until 12/2020 Algiers, Algeria",
      jobDescription: "- Won the first prize among 700 projects.",
      projectName: "- ReBorn Client mobile app.",
      projectLink: "https://expo.dev/@aylgroup/reborn",
      projectName2: "- ReBorn Barber mobile app.",
      projectLink2: "https://expo.dev/@aylgroup/rebornpartner",
      projectNam3: "- Playlist of Reborn Barber app (If the above links don't work).",
      projectLink3: "https://www.youtube.com/playlist?list=PLL5YhlP2ibGaiEUMD68q4RYxxn_kaQsGy",
      projectNam3: "- Pitch deck in front of the Minister of Startups.",
      projectLink3: "https://www.linkedin.com/feed/update/urn:li:activity:6765546695582388224/",
      info: "- Present numerous pitch decks to convincingly attract investment from accelerators and business angels for my project.",
      info2: "- Assist online monitoring private sessions with Mr. Lotfi Belkhir, the professor from McGill University in Canada.",
      info3: "- Assist online training session before the final with Brian Tracy.",
      info4: "- Write a quality and clean code for both platforms (Android & iOS) to ensure the functioning of the mobile application.",
      info6: "- Contribute to the implementation of new technologies (React native and Redux) and architectural patterns to improve system scalability and performance.",
      info5: "- Integrate Rest APIs to retrieve and display real-time data.",
      info7: "- Implement back-end features with Node.js/Express to ensure the proper functioning of the mobile application.",
      info8: "- Design the database flow, write Mysql queries and integrate phone authentication using Firebase.",
      info9: "- Deploy the Android ReBorn barber app to Google store."
   },

   {
      class: "timeline-panel-container-inverted",
      job: "Web developer and Project Manager",
      company: "DX Solutions",
      companyWebsite: "https://www.bachene-soft.com/",
      date: " From 01/2020 until 01/2021 Medea, Algeria",
      jobDescription:
         "- Managing client meetings, establishing project timelines, and creating websites from the scratch.",
      projectName: "- University Saad Dahlab website (USDB)",
      projectLink: "- https://univ-blida.dz",
      projectName2: "- Superior Paramedical Institute website of Mostaganem city",
      projectLink2: "- https://infspm-mosta.dz",
      projectName3: "-  Paramedical Institute website of Chlef city",
      projectLink3: "- https://ifpmchlef.ayoungleader.com/",
      projectName4: "- Private Paramedical Institute website of Oran city",
      projectLink4: "- https://epfpm-elbibane.com",
      info: "- Crafting code that is well-designed, testable, and efficient through the utilization of industry-best software development practices.",
      info2: "- Consulting with internal stakeholders to determine the website's desired appearance and functionalities.",
      info3: "- Engaging in consultations with internal stakeholders to ascertain the preferred appearance and functionalities of the website.",
      info4: "- Providing training and mentorship to the IT department staff within these institutions."
   },
   {
      class: "timeline-panel-container",
      job: "Full-Stack developer",
      company: "Ege Test Center",
      companyWebsite: "https://www.egetestcenter.com/",
      date: " From 18/11/2019 to 31/12/2019 Izmir, Turkey",
      jobDescription: "- Developing, researching, executing, and overseeing the CertifyMyProduct e-commerce platform.",
      projectName: "- CertifyMyProduct e-commerce platform",
      projectLink: "https://certifymyproduct.com/",
      info: "- Writing and implementing efficient code.",
      info2: "- Working closely with other developers, UX designers, business and systems analysts.",
      info3: "- Solving security threat of malicious or unwanted malware Adwords.",
      info4: "- Technical Support Request (Godaddy, Bluehost and Google) | French & English"
   },
   {
      class: "timeline-panel-container-inverted",
      job: "Full-Stack developer",
      company: "DVLX Company",
      companyWebsite: "https://mobilmed.com.tr",
      date: " From 5/10/2019 to 15/11/2019 in Izmir, TURKEY",
      jobDescription: "- Developing and maintaining Mobilmed e-commerce platform.",
      projectName: "- Mobilmed e-commerce platform.",
      projectLink: "https://mobilmed.com.tr",
      info: "- Writing and implementing efficient code.",
      info2: "- Managing CMS dashboard.",
      info3: "- Technical Support Request (Godaddy) | French & English"
   },
   {
      class: "timeline-panel-container",
      job: "Junior Web Developer",
      company: "Shiftin Agency",
      companyWebsite: "https://shiftin.co/",
      date: " From 05/08/19 to 05/09/19 in Algiers",
      jobDescription: "- Developing company's blog from scratch using front-end and back-end features.",
      projectName: "- Shiftin's Blog",
      projectLink: "https://shiftin.co/blog",
      info: "- Writing and implementing efficient back-end code.",
      info2: "- Integrating the blog into a CMS using PHP.",
      info3: "- Working closely with Web development team.",
      info4: "- Collaborating with IT department using Slack."
   },
   {
      class: "timeline-panel-container-inverted",
      job: "Wordpress Developer",
      company: "Celership Algérie",
      companyWebsite: "https://www.linkedin.com/company/celership/about/",
      date: " From 26/06/18 to 26/08/18 Hydra Algiers",
      jobDescription: "- Developing Wordpress websites for specific clients.",
      info: "- Writing and implementing efficient front-end code.",
      info2: "-Collaborating with UI designers.",
      info3: "- Troubleshooting websites' issues and bugs.",
      info4: "- Maintaining and updating websites."
   },
   {
      class: "timeline-panel-container",
      job: "Trainer",
      company: "ISchoolGenius",
      companyWebsite: "https://www.facebook.com/ischoolgenius",
      date: " From 15/02/2017 to 04/07/2019",
      jobDescription: "- Training students, entrepreneurs and small business owners on the Web's fundamentals.",
      info: "- Build your website with WordPress + Intro to E-commerce at ISchoolGenius School",
      info2: "- 3 editions of Introduction to Web Development and Learn WordPress - Create a Beautiful Dynamic Website at CleverTech Startup"
   }
];

export default professionalExperience;
